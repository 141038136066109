@import '/src/styles/utilities.scss';

@mixin calculate-slide-sizes($breakpoint, $peek: '0px') {
  // Reference: https://github.com/davidjerleke/embla-carousel/issues/593#issuecomment-1756996816

  // Everything is based on the number of visible slides, which is configured
  // per breakpoint
  --visible-slides: var(--slides-to-show-#{$breakpoint});

  // Gaps between slides, which is based on slides - 1 converted to a
  // percentage
  --visible-gaps: calc((var(--visible-slides) - 1) / var(--visible-slides));
  --visible-gap-width: calc(var(--slide-gap) * var(--visible-gaps));

  // How much of the carousel viewport each slide should take up
  --visible-slide-width: calc(100% / var(--visible-slides));

  // Finally, the column is the width of the slide minus the gap
  // - This needs to add up to 100%, otherwise the slides won't fill the
  //   entire carousel viewport
  --slide-column-width: calc(
    var(--visible-slide-width) - var(--visible-gap-width) - #{$peek}
  );
}

.carousel {
  @include media-breakpoint-only(xs) {
    // Add peek since we hide the arrows on this breakpoint
    @include calculate-slide-sizes(xs, var(--slide-peek));
  }

  @include media-breakpoint-only(sm) {
    // Add peek since we hide the arrows on this breakpoint
    @include calculate-slide-sizes(sm, var(--slide-peek));
  }

  @include media-breakpoint-only(md) {
    @include calculate-slide-sizes(md);
  }

  @include media-breakpoint-only(lg) {
    @include calculate-slide-sizes(lg);
  }

  @include media-breakpoint-only(xl) {
    @include calculate-slide-sizes(xl);
  }

  @include media-breakpoint-only(xxl) {
    @include calculate-slide-sizes(xxl);
  }
}

.carousel__header {
  display: flex;
  flex-direction: column;
  gap: $space-12;
  margin-bottom: $space-12;
}

.carousel__contents {
  position: relative; // support ArrowButtons
}

.carousel__scrollViewport {
  overflow: hidden;

  &_hasOverflow {
    overflow: visible;
  }
}

.carousel__scrollContainer {
  display: grid;
  grid-auto-columns: var(--slide-column-width);
  grid-auto-flow: column;
  gap: var(--slide-gap);
  touch-action: pan-y pinch-zoom;

  > * {
    min-width: 0;
  }
}
