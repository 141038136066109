@import '/src/styles/utilities.scss';

.createAccount {
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100vh;

  @include media-breakpoint-between(md, lg) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1.5fr;
  }
}

.createAccount__contentWrapper {
  z-index: $zindex-active;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: $space-36;
  background-color: var(--color-white);
  box-shadow: $elevation-5;

  @include media-breakpoint-up(lg) {
    padding: $space-48 $space-64 $space-64;
  }
}

.createAccount__logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: $space-64;
}

.createAccount__imageWrapper {
  margin-top: $space-48;
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.createAccount__image {
  max-width: var(--image-size);
  height: auto;
}

.createAccount__link {
  @include apply-display-styles('subtitle1');

  color: var(--color-gocomics-blue);
  text-decoration: underline;

  &:hover,
  &:focus {
    color: color-lighten(var(--color-gocomics-blue), 30%);
  }
}

.createAccount__background {
  display: none;
  max-height: 100dvh;
  background: var(--background-image);
  background-repeat: no-repeat;
  background-position: center 30%;
  background-size: auto 130%;

  @include media-breakpoint-up(md) {
    display: block;
  }
}
