@import '/src/styles/utilities.scss';

$arrow-button-size: 70px;
$arrow-button-offset: $space-20;
$hover-effect-increase: 20px;
$hover-effect-height: calc(100% + $hover-effect-increase);
$hover-effect-width: $arrow-button-size + $hover-effect-increase;

.arrows {
  $viewport-edge: calc(50% - 50vw);

  position: absolute;
  inset: 0 calc($viewport-edge + $arrow-button-offset);
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;

  // Prevent this from being clickable and breaking interactions in the carousel
  pointer-events: none;
  visibility: hidden;
  opacity: 0;

  @include media-breakpoint-up(sm) {
    visibility: visible;
    opacity: 1;
  }
}

.arrows__button {
  @include undo-default-button-styles();

  display: flex;
  align-items: center;
  justify-content: center;
  width: $arrow-button-size;
  height: $arrow-button-size;
  color: var(--color-white);

  // Restore clicks for buttons
  pointer-events: all;
  background-color: color-opacity(var(--color-black), 0.25);
  border-radius: 50%;
  transition: all 0.2s ease;

  &:disabled {
    opacity: 0;
  }

  &:hover:not(:disabled) {
    width: $hover-effect-width;
    height: $hover-effect-height;
    border-radius: $border-radius-20 0 0 $border-radius-20;
    transform: translateX($arrow-button-offset);

    &:first-of-type {
      border-radius: 0 $border-radius-20 $border-radius-20 0;
      transform: translateX(-$arrow-button-offset);
    }
  }

  // Visually center the icon
  // since the left icon is flipped we can apply the same padding on both icons
  > * {
    padding-left: 4px;
  }

  // Flip the left icon
  &:first-of-type > * {
    transform: scale(-1);
  }
}
