@import '/src/styles/utilities.scss';

:export {
  var_iconColor: var(--color-gocomics-blue);
}

.menu__item {
  display: flex;
  gap: $space-32;
  align-items: center;
  justify-content: flex-start;
  padding: $space-16;
  font-family: $font-primary;
  font-weight: $font-weight-bold;
  color: var(--color-text-dark);
  border-radius: 0;

  &:hover {
    color: var(--color-text-dark);
  }
}

.menu__trigger {
  @include toolbar-button-effects();

  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  // Same size as the other toolbar buttons
  padding: $space-12;
}

.menu__hr {
  margin: 0;
}
