@import '/src/styles/utilities.scss';
@import '/src/styles/variables/sizing.scss';

.headerAd {
  position: relative;
  height: 100px;

  @include media-breakpoint-up(lg) {
    height: 280px;
  }
}

.headerAd__container {
  position: relative;
  height: 100px;

  @include media-breakpoint-up(lg) {
    height: 280px;
  }

  &_sticky {
    position: fixed;
    top: 0;
    z-index: $zindex-sticky-ad;
    width: 100%;
    transition: all 0.3s ease-in-out;
  }
}
