@import '/src/styles/utilities.scss';

.adDisplay {
  display: flex;
  align-items: center;
  height: 100%;

  &_unset {
    align-items: unset;
  }

  &_grayBackground {
    background-color: var(--color-gray-100);
  }
}
