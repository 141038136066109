@import '/src/styles/utilities.scss';

$trigger-size: 32px;

.offCanvasTrigger__wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &_indicator {
    height: $trigger-size;
  }
}

.offCanvasTrigger__indicator {
  position: absolute;
  bottom: -($trigger-size * 0.5);
  left: 50%;
  z-index: $zindex-active;
  width: $trigger-size * 0.5;
  height: $trigger-size * 0.5;
  background-color: var(--color-white);
  border-radius: 50%;
  transform: translateX(-50%);
}
