@import '/src/styles/utilities.scss';

.richTextParser {
  @include apply-display-styles('body1');

  p:not(:last-child) {
    margin-bottom: 30px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include apply-display-styles('d2');

    margin-bottom: 18px;
  }
}
