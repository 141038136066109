@import '/src/styles/utilities.scss';

.currentPaymentInfo {
  padding: $space-20 $space-24;
  border-radius: $border-radius-20;
  box-shadow: $elevation-5;

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: auto auto;
    padding: $space-36 $space-56;
  }
}

.currentPaymentInfo__details {
  display: flex;
  gap: $space-16;
  align-items: center;

  @include media-breakpoint-down(md) {
    justify-content: center;
    margin-bottom: $space-20;
  }
}

.currentPaymentInfo__ccIssuer {
  border: 1px solid var(--color-gray-100);
  border-radius: $border-radius-15;
}

.currentPaymentInfo__ccNum {
  color: var(--color-text-dark);
}

.currentPaymentInfo__ccExpiry {
  display: block;
  color: var(--color-gray-400);
}

.currentPaymentInfo__button {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    max-width: 28.2rem;
  }
}
