@import '/src/styles/utilities.scss';

$dialog-default-width: 380px;

.offCanvasContent__overlay {
  position: fixed;
  inset: var(--header-height, 0) 0 0 0;
  z-index: $zindex-offcanvas;
  background-color: rgb(0 0 0 / 40%);
  animation: overlay-show 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.offCanvasContent__dialog {
  position: fixed;
  top: var(--header-height, 0);
  bottom: 0;
  z-index: $zindex-offcanvas;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  box-shadow: $elevation-4;
  animation-duration: 250ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);

  &_submenu {
    position: absolute;
    top: 0;
    z-index: $zindex-active;
    height: 100%;
  }

  &_left {
    left: 0;
    max-width: var(--offcanvas-max-width, $dialog-default-width);
    animation-name: slide-in-left;

    &[data-state='closed'] {
      animation-name: slide-out-left;
    }
  }

  &_containedLeft {
    @include media-breakpoint-only(xs) {
      left: calc((100% - #{$container-xs}) / 2);
    }
    @include media-breakpoint-only(sm) {
      left: calc((100% - #{$container-sm}) / 2);
    }
    @include media-breakpoint-only(md) {
      left: calc((100% - #{$container-md}) / 2);
    }
    @include media-breakpoint-only(lg) {
      left: calc((100% - #{$container-lg}) / 2);
    }
    @include media-breakpoint-only(xl) {
      left: calc((100% - #{$container-xl}) / 2);
    }
    @include media-breakpoint-only(xxl) {
      left: calc((100% - #{$container-xxl}) / 2);
    }

    max-width: var(--offcanvas-max-width, $dialog-default-width);
    animation-name: slide-in-left;

    &[data-state='closed'] {
      animation-name: slide-out-left;
    }
  }

  &_right {
    right: 0;
    max-width: var(--offcanvas-max-width, $dialog-default-width);
    animation-name: slide-in-right;

    &[data-state='closed'] {
      animation-name: slide-out-right;
    }
  }

  &_containedRight {
    @include media-breakpoint-only(xs) {
      right: calc((100% - #{$container-xs}) / 2);
    }
    @include media-breakpoint-only(sm) {
      right: calc((100% - #{$container-sm}) / 2);
    }
    @include media-breakpoint-only(md) {
      right: calc((100% - #{$container-md}) / 2);
    }
    @include media-breakpoint-only(lg) {
      right: calc((100% - #{$container-lg}) / 2);
    }
    @include media-breakpoint-only(xl) {
      right: calc((100% - #{$container-xl}) / 2);
    }
    @include media-breakpoint-only(xxl) {
      right: calc((100% - #{$container-xxl}) / 2);
    }

    max-width: var(--offcanvas-max-width, $dialog-default-width);
    animation-name: slide-in-right;

    &[data-state='closed'] {
      animation-name: slide-out-right;
    }
  }
}

@keyframes overlay-show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slide-in-left {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-out-left {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

@keyframes slide-in-right {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-out-right {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(100%);
  }
}
