@import '/src/styles/utilities.scss';

$dot-offset: 8%;

// In masks, the color is only useful for debugging (see notes below), while
// opacity will affect the mask itself.
$mask-fill-debug: linear-gradient(rgba(pink, 0.5) 0 0);
$mask-fill: linear-gradient(rgba(black, 1) 0 0);
$mask-circle: url('/images/decorators/Global-Feature-Badge-BG-Solid.svg');

@mixin dot-circle-mask($debug: false) {
  // Notes:
  // - You can debug the mask by swapping "mask" with "background".
  // - 2+ images are needed for mask-composite, because it is compositing
  // multiple images into a single mask. We use the first image to fill the
  // entire box, and the second image used to cut a circle from the filled
  // box. This remaining shape is the final mask and is where the dots will
  // be visible.
  @if $debug == true {
    background-image: $mask-fill-debug, $mask-circle;
    background-repeat: no-repeat;
    background-position: center, center;
    background-size:
      cover,
      100% - $dot-offset * 2;

    // no background equivalent for mask-composite
  } @else {
    mask-image: $mask-fill, $mask-circle;
    mask-repeat: no-repeat;
    mask-position: center, center;
    mask-size:
      cover,
      100% - $dot-offset * 2;
    mask-composite: subtract;
  }
}

.badge {
  // This component is usually used inline and this prevents it from
  // stretching and breaking in unexpected ways if it lives inside a
  // flex or grid container.
  display: inline-flex;
  justify-content: center;
  aspect-ratio: 1 / 1;
}

.badge__layout {
  display: grid;
  grid-template: minmax(20px, 1fr) / minmax(20px, 1fr);
  grid-template-areas: 'badge';
  place-items: end center;
}

.badge__background {
  grid-area: badge;
  width: 100%;
  height: auto;

  // Padding reserves space for the dots and help prevent clipping and
  // unhelpful bounding boxes.
  padding: $dot-offset;
  color: var(--badge-color, var(--color-white));
  opacity: 0.65;

  &_dots {
    @include dot-circle-mask($debug: false);

    svg {
      // Offset the dots, but because we're creating a new stacking context we
      // have to add a transform to the other elements.
      transform: translate(-$dot-offset, $dot-offset);
    }
  }

  &_circle {
    // This gets a transform to live in the same stacking context as the dots.
    transform: translateZ(0);
  }

  &_solid {
    opacity: 1;
  }
}

.badge__image {
  grid-area: badge;
  width: 100%;
  height: auto;

  // Padding and transform to match .badge__background
  padding: $dot-offset;
  object-fit: contain;
  transform: translateZ(0);
}
