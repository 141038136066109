@import '/src/styles/utilities.scss';

.label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: $space-8;
  padding-left: $space-8;
  color: var(--color-text-light);
  background-color: var(--color-gocomics-blue);
  border-radius: $border-radius-50;
}
