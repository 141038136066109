@import '/src/styles/utilities.scss';

.mainNavigationBar {
  position: relative;
  width: 100%;

  // Add height so that the bar is visible immediately and there is no layout shift
  height: $height-nav-mobile;
  background-color: var(--color-gray-700);
  box-shadow: $elevation-4;

  @include media-breakpoint-up(lg) {
    height: $height-nav-desktop;
  }
}
