@import '/src/styles/utilities.scss';

$link-size: 30px;

.socialLinks {
  display: flex;
  gap: $space-12;
  justify-content: flex-start;
}

.socialLinks__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $link-size;
  height: $link-size;
  background-color: var(--color-white);
  border-radius: 50%;
}

.socialLinks__image {
  width: var(--social-link-size);
  height: var(--social-link-size);
  object-fit: contain;
}
