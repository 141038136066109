@import '/src/styles/utilities.scss';

$logo-width: 225px;

.footer {
  padding: $space-60 0;
  margin-top: auto; // Position footer at bottom of page
  background-color: var(--color-gray-700);
}

.footer__content {
  @include create-container();

  display: grid;
  grid-template-areas:
    'logo'
    'nav'
    'social'
    'copyright';
  grid-auto-flow: row;
  gap: $space-44;

  @include media-breakpoint-up(lg) {
    grid-template-areas:
      'logo nav'
      '. nav'
      'social nav'
      'copyright nav';
    grid-template-columns: 1fr 2fr;
  }
}

.footer__logo {
  grid-area: logo;
  width: $logo-width;
}

.footer__navigation {
  grid-area: nav;
}

.footer__social {
  grid-area: social;
}

.footer__copyright {
  grid-area: copyright;
}
