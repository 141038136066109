@import '/src/styles/utilities.scss';

.gridDeck {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row dense;
  gap: $space-20 $space-16;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(3, 1fr);
    gap: $space-32 $space-16;
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(4, 1fr);
  }
}
