@import '/src/styles/utilities.scss';

.containerWrapper {
  display: block;
  width: 100%;
  max-width: 100%;
  background-color: var(--background-color);
  @include responsive-properties('padding');
  @include responsive-properties('margin');
}
