@import '/src/styles/utilities.scss';

.navigationSections {
  display: flex;
  flex-flow: row wrap;
  gap: $space-20;
  justify-content: space-between;
}

.navigationSections__section {
  padding: 0;
  margin: 0;
  list-style-type: none;

  li:not([class]) {
    margin-bottom: $space-20;
  }
}

.navigationSections__heading {
  margin-bottom: $space-32;
  color: var(--color-gray-300);
  white-space: nowrap;

  > * {
    margin-bottom: 0;
  }
}

.navigationSections__link {
  color: var(--color-text-light);

  &:hover {
    color: var(--color-text-light);
  }
}
