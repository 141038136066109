@import '/src/styles/utilities.scss';

.dropdownContent {
  z-index: $zindex-dropdown;
  width: var(--width, 100%);
  height: var(--height, auto);
  background-color: var(--color-white);
  border-radius: var(--border-radius, $border-radius-15);
  box-shadow: $elevation-4;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &_dropstart {
    &[data-state='open'] {
      animation-name: slide-in-from-left;
    }

    &[data-state='closed'] {
      animation-name: slide-out-to-left;
    }
  }

  &_dropend {
    &[data-state='open'] {
      animation-name: slide-in-from-right;
    }

    &[data-state='closed'] {
      animation-name: slide-out-to-right;
    }
  }

  &_dropdown {
    &[data-state='open'] {
      animation-name: slide-down-from-top;
    }

    &[data-state='closed'] {
      animation-name: slide-up-to-top;
    }
  }

  &_dropup {
    &[data-state='open'] {
      animation-name: slide-up-from-bottom;
    }

    &[data-state='closed'] {
      animation-name: slide-down-to-bottom;
    }
  }
}

.dropdownContent__arrow {
  fill: var(--color-white);
}

@keyframes slide-in-from-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-out-to-left {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

@keyframes slide-in-from-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-out-to-right {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes slide-down-from-top {
  0% {
    opacity: 0;
    transform: translateY(-2px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-up-to-top {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(2px);
  }
}

@keyframes slide-up-from-bottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-down-to-bottom {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}
