@import '/src/styles/utilities.scss';

$dot-size: 8px;
$dot-size-active: 32px;

.indicators {
  display: flex;
  gap: $space-4;
  align-items: center;
  justify-content: flex-end;
}

.indicators__dot {
  @include undo-default-button-styles();

  display: inline-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $dot-size;
  height: $dot-size;
  background-color: var(--color-gray-300);
  border-radius: $border-radius-50;
  transition: all 250ms ease;

  &_isActive {
    width: $dot-size-active;
    background-color: var(--color-gocomics-blue);
  }
}
