@import '/src/styles/utilities.scss';

.badgeByline__link {
  display: inline-flex;
  align-items: center;
}

.badgeByline__badge {
  width: var(--badge-size);
  margin-right: $space-12;
}

.badgeByline__byline {
  color: var(--color-text-dark);
}
